.about-us-container {
    margin-top: 160px;
    height: 204px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 169px;
}
.title-1 {
    text-align: right;
    height: 40px;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0px;
    color: #333333;
}
.title-2 {
    text-align: right;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0px;
    color: #d16a6f;
}
.line {
    width: 2px;
    height: 250px;
    background-color: #d16a6f;
    margin: auto 50px;
}
.about-us-container .address-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about-us-container .address-container .address-img {
    margin-left: 100px;
    width: 306px;
    height: 206px;
}
.about-us-container .detail-address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detail-address {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.detail-address p {
    display: flex;
    align-items: center;
    margin: 17px 0;
    font-size: 27px;
    height: 27px;
    line-height: 27px;
}
.detail-address p i {
    font-style: normal;
    margin-left: 8px;
    color: #000;
    font-size: 17px;
    height: 17px;
    line-height: 17px;
}
