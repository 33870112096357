.footer-copy p {
    font-family: MicrosoftYaHei;
    margin: 5px 0 5px;
    color: rgba(255, 255, 255, 0.8);
}

.footer-copy p a {
    color: rgba(255, 255, 255, 0.8);
}

footer {
    margin-top: 120px;
    padding: 50px 0;
    background-color: #d16a6f;
    min-width: 1550px;
    overflow: hidden;
    background: url(../../assets/images/footer-bg.png) no-repeat;
    background-size: cover;
}

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.footer-copy {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-copy .police-link {
    color: #e67e22;
    display: flex;
    align-items: center;
}

.footer .logo {
    margin: 50px auto;
}

footer .logo a img {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
}

.footer-guide-container {
    margin-bottom: 50px;
}

.footer-guide-container ul > li {
    margin: 0 30px;
}

.footer-guide,
.footer-split-line {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: 0px;
    color: #ffffff;
}

.footer-split-line {
    width: 3px;
    height: 21px;
    background-color: #fff;
}

.footer-guide {
    height: 19px;
}

.footer .logo ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.QR-code-container {
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.QR-code-container .QR-code {
    margin: 0 60px;
}

.QR-code-container .QR-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QR-code p {
    margin-top: 10px;
    text-align: center;
    height: 14px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #ffffff;
}

.QR-code {
    img {
        width: 100px;
        height: 100px;
    }
}
