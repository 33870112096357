.bar {
    height: 100vh;
    background-size: 100%;
}

.four-ours-container {
    margin-top: 113px;
}
.main-part-one {
    margin-top: 110px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.main-part-one div {
    display: flex;
    justify-content: center;
}
.home {
    min-width: 1492px;
    overflow: hidden;
    margin: 0 auto;
}

