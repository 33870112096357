.ours {
    width: 266px;
    height: 284px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 4.5rem;
}
.ours .img-container{
    width:100px;
    height: 100px;
}
.ours .img-container img {
    width: auto;  
    height: auto;  
    max-width: 100%;  
    max-height: 100%;
}
.ours p {
    height: 34px;
    font-family: AlibabaPuHuiTiL;
    font-size: 36px;
    line-height: 34px;
    letter-spacing: 0px;
    color: #333333;
}
article {
    width: 265px;
    height: 94px;
    font-family: AlibabaPuHuiTiL;
    font-size: 18px;
    font-weight: lighter;
    line-height: 34px;
    letter-spacing: 0px;
    color: #333333;
}
.four-ours {
    display: flex;
    justify-content: center;
    align-items: center;
}
