.nav-about-us-container {
    height: 730px;
    position: relative;
}

.nav-about-us {
    position: absolute;
    width: 100%;
    top: 115px;
    height: 600px;
    background: url(../../../assets/images/banner-us.png) no-repeat;
    background-size: cover;
}

.about-us-Intro {
    padding-top: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-us-Intro h2 {
    font-size: 60px;
    height: 60px;
    line-height: 60px;
    color: #fff;
}

.about-us-Intro .split {
    width: 290px;
    height: 2px;
    background-color: #fff;
    margin-top: 25px;
    margin-bottom: 40px;
}

.about-us-Intro h3 {
    font-size: 38px;
    height: 38px;
    line-height: 38px;
    color: #fff;
}