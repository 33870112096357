.why-choose-mmmy-container {
    align-items: center;
    flex-direction: column;
    margin: 42.5px 30px;
}

.why-choose-mmmy {
    width: 420px;
    height: 370px;
    background: url(../../../../assets/images/why-bg.png) no-repeat;
    background-position: center;
    position: relative;
    transition: transform linear 0.6s;
    transform-style: preserve-3d;
    margin-bottom: -10px;
    perspective: 1000;
}

.why-choose-mmmy .front,
.back {
    position: absolute;
    width: 388px;
    height: 342px;
    backface-visibility: hidden;
}

.why-choose-mmmy .front img {
    margin-top: -3px;
    width: 111%;
    height: 111%;
}

.why-choose-mmmy .title,
text {
    margin: auto;
    height: 28px;
    font-size: 28px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0px;
    color: #d16a6f;
}

.why-choose-mmmy .text {
    margin: 0 auto;
    width: 272px;
    height: auto;
    font-size: 18px;
    line-height: 35px;
    color: #fff;
}

.revers-front {
    font-size: 28px;
    text-align: center;
    letter-spacing: 0px;
    width: 368px;
    height: 54px;
    line-height: 54px;
    border-radius: 27px;
}

.revers-container:hover .why-choose-mmmy {
    transform: rotateX(180deg);
}

.revers-container:hover ~ .revers-front {
    background-color: #d16a6f;
    color: #fff;
    transition: all linear 0.4s 0.4s;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.17);
    backface-visibility: hidden;
}

.why-choose-mmmy-container .back {
    transform: rotateX(180deg);
    flex-direction: column;
}

.why-choose-mmmy .front {
    z-index: 2;
}
