* {
    margin: 0;
    padding: 0;
    font-family: MicrosoftYaHei;
}
.about-us-container {
    margin-top: 160px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 169px;
}
.title-1 {
    text-align: right;
    height: 40px;
    font-size: 40px;
    line-height: 16px;
    letter-spacing: 0px;
    color: #333333;
}
.title-2 {
    text-align: right;
    font-size: 40px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #d16a6f;
}
.line {
    width: 2px;
    height: 250px;
    background-color: #d16a6f;
    margin: auto 50px;
}
.about-us-container article {
    margin-top: -40px;
    width: 820px;
    height: 163px;
    font-size: 18px;
    font-weight: normal;
    line-height: 36px;
    letter-spacing: 0px;
    color: #333333;
}
