.split-line {
    margin-top: 160px;
    margin-bottom: 120px;
    display: flex;
    height: 45px;
    justify-content: center;
    align-items: center;
}
.split-line img {
    height: 30px;
}
.split-line p {
    margin: 10px 21px;
    height: 45px;
    font-family: AlibabaPuHuiTiL;
    font-size: 47px;
    font-weight: lighter;
    line-height: 45px;
    letter-spacing: 0px;
    color: #333333;
}
