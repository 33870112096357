.part-one-container {
    height: 730px;
    position: relative;
}

.part-one-container .part-one {
    position: absolute;
    width: 100%;
    top: 106px;
    height: 600px;
    background: url(../../../assets/images/banner-course.png) no-repeat;
    background-size: cover;
}