* {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

.nav-container {
    height: 12vh;
    z-index: 10;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 1492px;
}

nav {
    height: 12vh;
    display: flex;
    width: 1900px;
    justify-content: space-between;
    align-items: center;
    min-width: 1492px;
}

.mmmy-container {
    display: flex;
    justify-content: center;
}

nav .logo {
    margin-left: 260px;
    height: 70px;
    /* background-color: #d16a6f; */
}

.guide {
    margin-right: 260px;
}

ul > li {
    display: flex;
    float: left;
    flex-direction: row;
    margin-left: 90px;
}

.guide li img {
    display: none;
    margin-top: -12px;
    width: 28px;
    height: 29px;
}

li a {
    font-family: AlibabaPuHuiTiR;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #fff;
}

.nav-container .guide li a:hover {
    color: #d16a6f !important;
}

.show-tag {
    display: block !important;
    color: #d16a6f !important;
}

.focusColor {
    color: #d16a6f !important;
}

.guide .minis-app {
    position: relative;
}

.guide .minis-app .show-qr-code:hover ~ .qr-code {
    visibility: visible;
}

.guide li .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 20px;
    left: -120px;
    position: absolute;
    width: 300px;
    height: 300px;
    background: url(../../assets/images/mini-bg.png) no-repeat;
    background-position: center;
    visibility: hidden;
}

.guide li .qr-code img {
    display: block;
    margin-bottom: -10px;
    margin-top: 10px;
    width: 150px;
    height: 150px;
}

.guide li .qr-code p {
    margin-top: 20px;
    font-size: 17px;
    color: #000;
}
