* {
    margin: 0;
    padding: 0;
}
.paragraph-container {
    position: relative;
    height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.paragraph {
    position: absolute;
    top: 40vh;
    text-align: center;
}
.slogan {
    padding-left: 2em;
    padding-right: 2em;
    color: #fff;
    font-size: 2em;
    border-radius: 2em;
    background-color: rgba(209, 106, 111, 0.6);
    height: 2em;
    line-height: 2em;
    margin-top: 1em;
}

div p {
    font-family: FZCQJW--GB1-0;
    font-weight: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: #d16a6f;
}
.p1 {
    height: 57px;
    line-height: 57px;
    font-size: 60px;
}
.p2 {
    line-height: 46px;
    height: 46px;
    font-size: 48px;
}
.video-container {
    overflow: hidden;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-container video {
    height: 107vh;
    object-fit: fill;
    width: 100%;
}
