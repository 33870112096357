* {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    font-family: MicrosoftYaHei;
    margin: 0;
    padding: 0;
}
.ant-layout {
    min-height: 100%;
}
.left {
    float: left;
}
.right {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    float: right;
}
.ant-layout.ant-layout-has-sider {
    height: 100vh;
    overflow: auto;
}
.text-right {
    text-align: right;
}
.mar-left {
    margin-left: 8px;
}
